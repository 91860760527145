.head_nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: grid;
  z-index: 50;
  max-width: 100%;
}

.head_nav * {
  max-width: 100%;
}

.top_navbar {
  display: flex;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.37);
  left: 0;
  color: black;
}

.top_navbar3 {
  display: flex;
  height: 60px;
  background-color: rgb(255, 255, 255);
  left: 0;
  color: white;
}

.buy {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.start_btn {
  width: 100%;
  margin: 10px auto;
}
.start_btn:hover {
    background-color: rgb(0, 0, 233) !important;
}

.hamburger {
  display: none;
  grid-area: ham;
  width: 40px;
  margin-right: 20px;
}

.alpha {
  background-color: rgba(50, 135, 220, 0.644);
  border-radius: 50%;
  color: white;
  height: 30px;
  width: 30px;
  font-size: x-large;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  flex: 4;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: left;
  padding-left: 50px;
  grid-area: logo;
}

.logbtn {
  border: 1px solid rgb(163, 163, 255);
  padding: 4px 30px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: rgba(163, 163, 255, 0.566) 2px 3px 0 1px;
  transition: all 0.4s ease-in-out;
}

.logbtn:hover {
  background-color: rgb(46, 49, 146);
  color: white;
}

.logo img {
  top: 20%;
}

.log_links {
  display: grid;
  text-align: left;
  gap: 2px;
}

.log_links * {
  background-color: rgb(255, 255, 255);
  padding: 0 10px;
}

.log_links *:hover {
  background-color: rgb(226, 226, 226);
  color: black;
}

.logout_btn {
  border: 1px solid rgb(163, 163, 255);
  padding: 4px 30px;
  width: 100%;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: rgba(163, 163, 255, 0.566) 2px 3px 0 1px;
  transition: all 0.4s ease-in-out;
}

.logout_btn:hover {
  box-shadow: rgba(163, 163, 255, 0.566) 0px 0px 3px;
}

.country {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: end;
  grid-area: country;
}

.country > * {
  padding: 2px 10px 2px 10px;
  background-color: rgba(244, 244, 244, 0);
  color: rgb(255, 255, 255);
  border-radius: 20px;
}

.country2 {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: end;
  grid-area: country;
}

.country2 > * {
  padding: 2px 10px 2px 10px;
  background-color: rgba(244, 244, 244, 0);
  color: rgb(0, 0, 0);
  border-radius: 20px;
}

.country > * > * {
  background-color: rgba(244, 244, 244, 0.384);
  color: rgb(0, 0, 0);
}

.login_data > * {
  padding: 2px 10px 2px 10px;
  background-color: rgba(244, 244, 244, 0);
  color: rgb(255, 255, 255);
  border-radius: 20px;
}

.login_data2 > * {
  color: black;
  padding: 2px 10px 2px 10px;
  background-color: rgba(244, 244, 244, 0);
  border-radius: 20px;
}

.login_box {
  flex: 1;
  display: flex;
  align-items: center;
  grid-area: login;
  justify-content: center;
}

.login_box > * {
  padding: 2px 10px 2px 10px;
  background-color: rgba(244, 244, 244, 0);
  color: rgb(105, 101, 101);
  border-radius: 20px;
}

.login_box button:hover {
  background-color: rgba(255, 255, 255, 0.447);
}

.login_box button {
  background-color: unset;
  padding: 0 10px;
}

.top_navbar > div:nth-child(4) {
  flex: 1;
}

.login_data {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
}

.login_data2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  justify-content: left;
  color: black;
}

.option > * {
  padding: 0 10px;
}

.option > *:hover {
  background-color: #d2ab67;
  color: white;
}

.nav_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px 0;
  background-color: rgba(0, 0, 0, 0.37);
  color: rgb(255, 255, 255);
}

.nav_bottom2 {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  display: none;
  align-items: center;
  justify-content: space-around;
  padding: 3px 0;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

@media screen and (min-width: 550px) and (max-width: 980px) {
  .top_navbar > * {
    flex: 1;
  }

  .top_navbar > div:nth-child(1) {
    display: flex;
  }

  .login_data > *:nth-child(2) {
    display: none;
  }

  .login_data > *:nth-child(3) {
    display: none;
  }

  .login_data > *:nth-child(4) {
    display: none;
  }

  .top_navbar3 > * {
    flex: 1;
  }

  .top_navbar3 > div:nth-child(1) {
    display: flex;
  }

  .login_data2 > *:nth-child(2) {
    display: none;
  }

  .login_data2 > *:nth-child(3) {
    display: none;
  }

  .login_data2 > *:nth-child(4) {
    display: none;
  }

  .logo {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .top_navbar {
    display: grid;
    grid-template-areas: "ham logo country login";
    height: fit-content;
    height: 50px;
  }

  .top_navbar3 {
    display: grid;
    grid-template-areas: "ham logo country login";
    height: fit-content;
    height: 50px;
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo img {
    width: 120px;
  }

  .country * {
    width: 90px;
  }

  .country2 * {
    width: 90px;
  }

  .country {
    width: 90px;
    padding: 0;
    border: 0;
    outline: 0;
  }

  .country2 {
    width: 90px;
    padding: 0;
    border: 0;
    outline: 0;
  }
}

@media screen and (min-width: 100px) and (max-width: 550px) {
  .top_navbar > * {
    flex: 1;
  }

  .top_navbar > div:nth-child(1) {
    display: flex;
  }

  .login_data > *:nth-child(2) {
    display: none;
  }

  .login_data > *:nth-child(3) {
    display: none;
  }

  .login_data > *:nth-child(4) {
    display: none;
  }

  .top_navbar3 > * {
    flex: 1;
  }

  .top_navbar3 > div:nth-child(1) {
    display: flex;
  }

  .login_data2 > *:nth-child(2) {
    display: none;
  }

  .login_data2 > *:nth-child(3) {
    display: none;
  }

  .login_data2 > *:nth-child(4) {
    display: none;
  }

  .logo {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .nav_bottom {
    display: none;
  }

  .nav_bottom2 {
    display: none;
  }

  .top_navbar {
    display: grid;
    grid-template-areas: "ham logo country login";
    height: fit-content;
    height: 50px;
  }

  .top_navbar3 {
    display: grid;
    grid-template-areas: "ham logo country login";
    height: fit-content;
    height: 50px;
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hamburger > * {
    width: 90%;
  }

  .logo img {
    width: 120px;
  }

  .country * {
    width: 90px;
    border: 2px solid blue;
  }

  .country {
    width: 90px;
    padding: 0;
  }

  .country2 * {
    width: 90px;
    border: 2px solid blue;
  }

  .country2 {
    width: 90px;
    padding: 0;
  }
}

/* Dropdown styles for desktop devices */
@media screen and (min-width: 981px) {
  /* Hide the dropdown content by default */
  .nav_bottom .dropdownContent {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    min-width: 200px;
    color: black !important;
  }

  /* Show the dropdown content on hover */
  .dropdown:hover .dropdownContent{
    display: block;
  }
  .dropdown::-webkit-scrollbar-track .dropdownContent{
    display: none;
  }

  /* Style the dropdown button */
  .dropdownButton {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
    font-weight: 400;
    padding: 10px;
  }

  /* Additional styling for dropdown items */
  .dropdownContent a,
  .dropdownContent .tabs {
    color: black !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
}
 @media  (max-width: 912px) {
  .dropdown {
    display: block !important;
  }
  .nav_bottom .dropdownContent {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    min-width: 200px;
    color: black !important;
  }
  /* Show the dropdown content on hover */
  .dropdown:hover .dropdownContent{
    display: block;
  }
  .dropdown::-webkit-scrollbar-track .dropdownContent{
    display: none;
  }

  /* Style the dropdown button */
  .dropdownButton {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
    font-weight: 400;
    padding: 10px;
  }

  /* Additional styling for dropdown items */
  .dropdownContent a,
  .dropdownContent .tabs {
    color: black !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

 }
.linkUnderline {
	border-bottom-width: 1;
	background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
	background-size: 0 4px;
	background-position: 0 100%;
	background-repeat: no-repeat;
	transition: background-size .5s ease-in-out;
	
}

/* .linkUnderlineBlack {
	background-image: linear-gradient(transparent, transparent), linear-gradient( rgb(255, 128, 128), rgb(233, 3, 3))
} */

.linkUnderline:hover {
	background-size: 100% 3px;
	background-position: 0 100%
}
