
.topbox{
    background-image: url("../../House//image-mainhome-3.jpg");  
    background-position: center;  
    background-size: cover;  
    padding: 30px ;    
    display: grid;   
    gap: 10px; 
} 


.mapbox{
    display: inline-table !important;
    /* align-items: center;
    justify-content: center;
    text-align: center !important; */

    text-align: justify ;
    height: 150px;
    max-width: max-content;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    position: relative;
    height: 200px !important;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
} 

.name{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.placeCenter{
    display: flex; 
    align-items: center; 
    justify-content: center; 
}
 
.desbox{
    --max-lines: 3; 
    display: -webkit-box; 
    overflow: hidden; 
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines) ;
    padding: 0 10px ;
    
}

.placeTop{
       position: absolute; 
       top: 20px; 
       right: 20px; 
       --f: 10px;
       --r: 15px;
       --t: 10px;
     .placeTop {
       --f: 10px;
       --r: 15px;
       --t: 10px;
   
       inset: var(--t) calc(-1 * var(--f)) auto auto;
       padding: 0 10px var(--f) calc(10px + var(--r));
       clip-path: polygon(
         0 0,
         100% 0,
         100% calc(100% - var(--f)),
         calc(100% - var(--f)) 100%,
         calc(100% - var(--f)) calc(100% - var(--f)),
         0 calc(100% - var(--f)),
         var(--r) calc(50% - var(--f) / 2)
       );
       background: #bd1550;
       box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
     }
       inset: var(--t) calc(-1 * var(--f)) auto auto;
       padding: 0 10px var(--f) calc(10px + var(--r));
       clip-path: polygon(
         0 0,
         100% 0,
         100% calc(100% - var(--f)),
         calc(100% - var(--f)) 100%,
         calc(100% - var(--f)) calc(100% - var(--f)),
         0 calc(100% - var(--f)),
         var(--r) calc(50% - var(--f) / 2)
       );
       background: #bd1550;
       box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
     
}
ul p{
    margin-left: 0 !important;
}




