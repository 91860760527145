
.leads{ 
    padding: 0 40px; 
    display: grid;  
    grid-template-columns: repeat(3,1fr);   
    text-align: left; 
    gap: 20px;  
} 

.leads *{
    max-width: 100%; 
}

.leads>div{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    padding: 20px;   
} 

.maxline{
    --max-lines: 3; 
  display: -webkit-box; 
  overflow: hidden; 
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines) ;
}


@media screen and (min-width:100px) and (max-width:560px) {
    .leads{ 
        padding: 10px;
        grid-template-columns: repeat(1,1fr);    
    }
}

@media screen and (min-width:560px) and (max-width:980px) {
    .leads{ 
        padding: 0;
        grid-template-columns: repeat(2,1fr);    
    } 
}
