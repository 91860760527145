  
.userterm{
    padding-top: 20px;  
    text-align: justify;  
    display: grid; 
    gap: 16px; 
    width: 90%; 
    font-size: small;  
    margin: auto;      
}   


.download_btn{
    display: flex; 
    align-items: center; 
    justify-content: end; 
}


