.link_box{
    display: flex; 
    flex-direction: column;  
    text-align: left;  
    /* border: 2px solid black;  */
    position:sticky; 
    top: 126px;  
    gap: 6px; 
}

.link_box>*{
    padding: 2px 6px;  
    font-size: large;  
} 

.link_box>*:hover{
    background-color: rgb(219, 238, 255);
}

 

 