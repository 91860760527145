.buy_top{
    padding: 60px 0; 
    border: 2px solid black;  
    display: flex;   
    align-items:baseline; 
    justify-content: center; 
    gap: 16px;  
    width: 98%; 
    max-width: 100%;  
    padding: 10px;  
    margin: 100px auto 0 auto;   
}


.sort{
    flex: 2;
    display: flex ;  
    align-items:baseline;  
    justify-content: flex-start; 
    flex-direction: column; 
    gap: 20px;  
    text-align: left;   
}

.sort>select{
    border: 2px solid black; 
    width: 100%; 

}

.contain{
    display: grid; 
    grid-template-columns: repeat(2,1fr); 
    width: 90%;  
    gap: 30px; 
    flex: 7;
    margin: auto;  
}

.contain img{
    width: 100%; 
}

