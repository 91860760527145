.signin_topbox {
  overflow: hidden;
  width: 100%; 
  height: auto;  
  position: relative;
  /* background-color: rgb(240, 240, 240);  */  
  background-image: url("./backimg.jpg"); 
  background-repeat: no-repeat; 
  background-size: cover;
}

.signin_topbox *{
  max-width: 100%;
}

.detail {
  display: flex; 
  color: rgb(0, 0, 0);
  width: 94%;
  margin: auto;  
  min-height: 80vh;
} 

.detail > * {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail .logbtn {
  background-color: rgb(46, 49, 146);
  color: white;
  width: 100%;
}

.detail .logbtn:hover {
  background-color: rgb(50, 50, 245);
  color: white;
}

/* .detail *{
  border: 2px solid white;
} */

.detail img {
  width: 90vh;
}

/* Login credentials css */

.log_info {
  background-color: rgba(255, 255, 255, 0.226); 
  backdrop-filter: blur(10px);   
  padding: 40px;  
  width: 560px;  
  height: 90%;  
  border-radius: 20px;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
} 

.resgister .tosign{
  color: blue; 
  cursor: pointer; 
}

.resgister .tosign:hover{
  text-decoration: underline; 
}

.boxback>*:nth-child(2){
  border: 1px solid rgb(94, 94, 244); 
}

@media screen and (min-width: 100px) and (max-width: 550px) {
  .login_img {
    display: none;
  }

  .detail {
    padding: 30px 0; 
  }
}

@media screen and (min-width: 550px) and (max-width: 980px) {
  .login_img {
    display: none;
  }
  .login_img img {
    width: 0;
  }

  /* .detail *{
    border: 2px solid white;
  }  */
  .detail {
    padding: 30px 0; 
  }
}
