
.mapbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; 
  max-width: 100%;  
} 

.maxwidth *{
  max-width: 100%; 
}

.mapbox > div { 
    padding: 20px;  
    height: 160px; 
    box-shadow: rgba(55, 0, 255, 0.24) 0px 3px 8px;   
}   

.posttype{
    position: absolute; 
    right: 10px; 
    top: 10px;  
    z-index: 10; 
}

.flex{
    display: flex;  
    gap: 20px; 
    align-items: flex-start; 
    justify-content: left;  
    position: relative;  
    border-radius: 2px;  
}   

.des{
  --max-lines: 3; 
  display: -webkit-box; 
  overflow: hidden; 
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines) ;
}

/* tablet */
@media screen and (min-width: 560px) and (max-width: 980px) {
  .mapbox {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* mobile */
@media screen and (min-width: 100px) and (max-width: 560px) {
  .mapbox {
    grid-template-columns: repeat(1, 1fr);
  }
}


