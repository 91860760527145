.pagetop {
  position: relative;
  min-height: 100vh;
}

/* background 2 video */

.video_picture {
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: -10;
}

.video_picture2 {
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: -8;
}

/* --===-- */

.video_panal {
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -2;
  object-fit: cover;
}

.white {
  color: white;
}

.topbox {
  position: relative;
  height: 100vh;
  /* border: 2px solid green;   */
}

.acolor a {
  color: rgb(40, 40, 241);
  cursor: pointer;
}

.acolor a:hover {
  text-decoration: underline;
}

/* buy */

.buy {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: justify;
  gap: 10px;
  margin: 20px 0;
}

.buy > * {
  font-size: small;
  background-color: white;
  padding: 2px 8px;
  border-radius: 10px;
}

.start_btn {
  width: 100%;
  background-color: rgb(46, 49, 146);
  color: white;
}

/* top button */

.fields > div:nth-child(1) > * {
  width: 10%;
}

.fields > div:nth-child(2) > *:nth-child(1) {
  width: 15%;
}

/* select city css */

.select_city * {
  color: black;
}

/* top section end */

.topbox > *:nth-child(1) {
  position: absolute;
  top: 20%;
  left: 5%;
  /* border: 2px solid yellow;  */
}

.topbox > *:nth-child(2) {
  position: absolute;
  bottom: 40%;  
}

.fields {
  max-width: 100%;
  width: 100%;
  margin: 20px 0;
  text-align: center;
  display: grid;  
  position: absolute; 
  gap: 20px;  
  /* border: 2px solid burlywood; */
}

.fields > div:nth-child(1) {
  /* border: 2px solid rgb(255, 0, 0);  */
  margin: auto 5%;
  text-align: center;
  display: flex;
  gap: 5px;
  align-items: center;
}

.fields > div:nth-child(1) > *:nth-child(1) {
  width: fit-content;
}

/* textShadow={"#000000 3px 1px 20px"}  */

.fields > div:nth-child(2) {
  margin-left: 14.3%;
  text-align: center;
  display: flex;
  position: relative;
  /* gap: 20px; */
  align-items: center;
}

.fields > div:nth-child(2) .searchbtn {
  border-radius: 0;
  color: white;
  padding: 0 10px 0 15px;
  z-index: 1;
  margin-left: -6px;
  clip-path: polygon(0% 0, 100% 1%, 100% 100%, 0% 100%, 20% 50%);
  border: 4px solid rgba(255, 255, 255, 0);
}

.fields > div:nth-child(2) input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#btn_serch {
  margin: 0 0 0 -14px;
  background-color: #d2ab67b0;
  width: 20px;
  height: 38px;
  clip-path: polygon(0 0, 50% 0, 100% 50%, 50% 100%, 0 100%, 50% 50%);
}
#btn_serch2 {
  margin: 0 0 0 -6px;
  background-color: rgb(46, 49, 146);
  width: 20px;
  height: 38px;
  clip-path: polygon(0 0, 50% 0, 100% 50%, 50% 100%, 0 100%, 50% 50%);
}

.word {
  -webkit-animation: slide-in-elliptic-top-fwd 1.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-top-fwd 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

@media screen and (min-width: 100px) and (max-width: 550px) {
  .fields > div:nth-child(2) {
    margin: auto 5%;
    text-align: center;
    display: flex;
    gap: 0;
    align-items: center;
  }

  .fields input {
    width: 100%;
  }

  .fields > div:nth-child(1) {
    margin: auto 5%;
    text-align: center;
    max-width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 4px;
  }

  .topbox > *:nth-child(1) {
    top: 8%;
  }

  .fields > div:nth-child(1) > * {
    width: 90%;
    margin: auto;
  }

  .fields > div:nth-child(2) > *:nth-child(1) {
    width: 55%;
    font-size: medium;
  }

  .fields > div:nth-child(1) > *:nth-child(1) {
    width: fit-content;
    /* border: 2px solid rgb(255, 255, 0);  */
  }

  .topbox > *:nth-child(2) {
    position: absolute;
    bottom: 40%;
  }

  #btn_serch {
    margin-left: -8px;
    clip-path: polygon(0 0, 50% 0, 100% 50%, 50% 100%, 0 100%, 50% 50%);
  }

  /* .fields > div:nth-child(2) .searchbtn {
    clip-path: polygon(22% 0, 100% 0, 100% 100%, 22% 100%, 1% 50%);
  } */
}
