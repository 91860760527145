
.loginpage{
    color: blue;
    cursor: pointer; 
} 

.loginpage:hover{
    text-decoration: underline;  
} 




