
body {
  margin: 0;   
  --main-one: rgb(12,32,50); 
  --dark-text-color: #ffffff; 
  --blue-light: rgba(114, 154, 191, 0.432) ;
  --color-btn: rgb(245, 101, 101);  
  background-color: rgb(245, 245, 245);   
}

button{
  outline: 0; 
}

select{
  outline: 0;  
}


*{ 
  margin: 0;
  padding: 0;  
} 


body::-webkit-scrollbar {
  width: 10px;
  background-color: white;  
}

body::-webkit-scrollbar-track {
  background-color: white;
  /* border-radius: 10px; */
}

body::-webkit-scrollbar-thumb {
  /* border-radius: 10px;  */ 
  background-color: white; 
  background-image: -webkit-gradient(
    linear, 
    40% 0%,
    75% 84%,
    from(rgb(101, 113, 245)),
    to(rgb(101, 202, 245)),
    color-stop(0.6, rgb(101, 111, 245))
  );
}  




