/* src/HomeLoanCalculator.css */

.container {
    max-width: 90vw;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .chart{
    display: flex;
    justify-content: space-between;
  }
  
  .h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .input-group input {
    width: calc(100% - 10px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .buttonchart {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .buttonchart:hover {
    background-color: #0056b3;
  }
  
  .chart-container {
    margin-top: 20px;
  }
  
  .chart-container h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }
  
  .chart-container2 h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }
  .chart-container2 {
    margin-top: 20px;
  }
  
  .chart-container2 canvas{
    margin: 0 auto;
    height:400px !important ;
     width: 400px !important
  }

.left {
    width: 50vw;
}
  