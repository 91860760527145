.App {
  text-align: center;
  max-width: 100%; 
  font-family: sans-serif;
} 
 

input[type="text"] {
  border-radius: 0; 
}
 
.App .plot *{
  max-width: 100%;
}

.info { 
  max-width: 2400px;
  margin: auto; 
}
 
.perfectwidth * {
  max-width: 100%; 
}

option {
  color: black;
  padding: 0 20px;
}

.scrole_button {
  position: fixed;
  top: 78%;
  right: 10px;
  display: grid;
  gap: 20px;
}

.scrole_button button {
  padding: 6px;
  background-color: var(--blue-light);
  color: black;
  border-radius: 50px;
  position: relative;
}

.scrole_button button:hover {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
  background-color: rgb(46, 49, 146);
}

.inquiry_form{
  position: fixed;
  bottom: 7%;  
  right: 60px;   
}

/* down button */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* .agentprofile > .chakra-avatar{
  height: 70px !important;
  width: 70px !important;
} */
