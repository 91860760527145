.management_box>*{
    border: 1px solid rgb(192, 192, 192);
    font-size: small;   
    text-align: justify; 
}

.textform{
    text-align: justify; 
    hyphens: auto;
}

.option_box{ 
    text-align: left; 
    display: flex; 
    flex-direction: column;  
    
}

.option_box *{ 
    background-color: rgb(245,245,245);  
    width: 100%;  
    font-size: small;  
}




