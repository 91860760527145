.footer {
  background-color: var(--main-one);
  color: var(--dark-text-color);
  /* color: black;   */
  padding-top: 80px;
  position: relative;
  /* margin-top: 30px; */
}

.footer_description {
  display: flex;
  justify-content: space-around;
  /* border: 2px solid yellow;  */
  height: auto;
  padding: 0 40px;
}

.br {
  display: none;
}

.footer_description > * {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: left;
  gap: 14px;
  flex: 1;
  padding: 20px;
  /* border: 2px solid yellow;   */
}

.footer_description > * > *:hover {
  text-decoration: underline;
}

.footer_description h2 {
  font-size: large;
  position: relative;
  margin-bottom: 10px;
}

.footer_description h2::after {
  content: "";
  position: absolute;
  border: 2px solid red;
  bottom: -10px;
  text-decoration: none;
  width: 40px;
  border-radius: 20px;
  left: 0;
}

.groupof {
  margin: 30px auto 0 auto;
  width: 90%;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.footer_option {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* contact icon */

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 24px 0;
}

.contact > * {
  border-radius: 18%;
  color: white;
  padding: 10px;
  object-fit: contain;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-color: #97c6f2bc;
}

.contact > *:hover {
  background-color: var(--dark-text-color);
  transform: scale(1.2);
}

.insta_logo {
  display: none;
}

.contact > *:nth-child(1):hover {
  color: rgb(18, 107, 196);
}

.contact > *:nth-child(2) .xicon1 {
  display: none;
  width: 24px;
}

.contact > *:nth-child(2) .xicon2 {
  display: flex;
  width: 24px;
}

.contact > *:nth-child(3):hover {
  color: #4267b2;
}

.contact > *:nth-child(4):hover .insta_icon {
  display: none;
}

.contact > *:nth-child(4):hover .insta_logo {
  display: flex;
}

.contact > *:nth-child(2):hover .xicon1 {
  display: flex;
  width: 24px;
}

.contact > *:nth-child(2):hover .xicon2 {
  display: none;
  width: 24px;
}

.location_detail {
  position: absolute;
  top: -20px;
  background-color: rgb(219, 224, 224);
  padding: 10px 20px 20px 20px;
  width: 98%;
  border-radius: 20px;
  text-align: left;
  left: 1%;
  display: grid;
  grid-template-areas:
    "name contact"
    "partner contact";
  color: white;
}

.location_detail > *:nth-child(1) {
  grid-area: "name";
}

.location_detail > *:nth-child(2) {
  grid-area: "contact";
}

.location_detail > *:nth-child(3) {
  grid-area: "partner";
  position: absolute;
  bottom: 5px;
  left: 20px;
}

.blue {
  color: rgb(46, 49, 146);
}

.red {
  color: orange;
}

.brs {
  display: none;
}

.location_detail > *:nth-child(1) {
  flex: 5;
  font-size: large;
  font-weight: 500;
  color: black;
}

.location_detail > *:nth-child(2) {
  flex: 5;
  font-size: large;
  color: black;
  margin-right: 4px;
  font-weight: 500;
}
.location_detail > *:nth-child(3) {
  flex: 2;
}

.location_detail button {
  background-color: var(--main-one);
  padding: 14px 36px;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: 10px;
}

.ft {
  position: relative;
  width: 100%;
  background-image: url("https://i.ibb.co/k1Z18HV/image-mainhome-3.jpg");
}

.location_detail > * {
  text-align: justify;
}

@media screen and (min-width: 550px) and (max-width: 980px) {
  .footer_option {
    display: grid;
    text-align: center;
  }

  .footer_option > *:nth-child(2) {
    text-align: center;
    display: grid;
    gap: 10px;
    margin: 5px 0;
  }

  .footer_description {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    margin-top: 80px;
  }
  .br {
    display: block;
  }

  .location_detail {
    display: grid;
    grid-template-areas:
      "name"
      "contact"
      "partner";
    justify-content: left;
    text-align: left; 
    padding: 10px 20px; 
  }

  .location_detail > * {
    width: 100%;
  }

  .location_detail > *:nth-child(3) {
    position: relative;  
    bottom: 0px;
    left: 0px;
  }
}

@media screen and (min-width: 100px) and (max-width: 550px) {
  .footer_option {
    display: grid;
    text-align: center;
  }

  .footer_option > *:nth-child(2) {
    text-align: center;
    display: grid;
    gap: 10px;
    margin: 5px 0;
  }

  .footer_description {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    padding: 0 20px;
    gap: 5px;
    margin: 130px 0 10px 0;
  }

  .footer_description > * {
    gap: 10px;
    flex: 1;
    /* box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px; */
    padding: 10px;
  }

  .location_detail {
    display: grid;
    grid-template-areas:
      "name"
      "contact"
      "partner";
    align-items: baseline;
    justify-content: left;
    padding: 10px 20px; 

  }

  .location_detail > *:nth-child(1) {
    text-align: left;
    padding: 0;
  }

  .location_detail > *:nth-child(3) {
    position: relative;  
    bottom: 0px;
    left: 0px;
  }

  .br {
    display: block;
  }

  .location_detail > *:nth-child(2) {
    text-align: left;
  }

  .brs {
    display: block;
  }

  .line {
    display: none;
  }
}
