.grid_city > * {
  background-color: rgb(174, 174, 174); 
}

.box > * {
  overflow: hidden; 
  transition: all 0.3s ease-in-out; 
}

.box > *:hover{
    transform: scale(1.08);  
  }

.box > *:nth-child(1) {
  height: 200px; 
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  /* filter: grayscale(100%); 
  -webkit-filter: grayscale(100%);  */
  border-radius: 10px;
  background-image: url("../homeImage/delhi.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.box > *:nth-child(2) {
  background-image: url("../homeImage/Gurugram.jpg");
  height: 200px; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.box > *:nth-child(3) {
  background-image: url("../homeImage/noida.png");
  height: 200px; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */ 
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.box > *:nth-child(4) {
  background-image: url("../homeImage/mumbai.jpg");
  height: 200px; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom; 
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900; 
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.box > *:nth-child(5) {
  background-image: url("../homeImage/bangalore.jpg");
  height: 200px; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.box > *:nth-child(6) {
  background-image: url("../homeImage/howrah-bridge.jpg");
  height: 200px; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
} 





.box3 > * {
  overflow: hidden; 
  transition: all 0.3s ease-in-out; 
}

.box3 > *:hover{
    transform: scale(1.08);  
  }

.box3 > *:nth-child(1) {
  height: 200px; 
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: bottom; 
  /* filter: grayscale(100%); 
  -webkit-filter: grayscale(100%);  */
  border-radius: 10px;
  background-image: url("./new_york.jpeg");
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.box3 > *:nth-child(2) {
  background-image: url("./new_jercy.jpeg");  
  height: 200px; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.box3 > *:nth-child(3) {
  background-image: url("./miami.jpeg"); 
  height: 200px; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* filter: grayscale(100%);
  -webkit-filter: grayscale(100%); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 900;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

