.displaygrid {
  display: grid;  
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 20px;  
  margin: 10px 0 40px 0;
}
.avtar-listing .chakra-avtar img{
   height: 5rem;
}
.listhead {
  text-align: left;
  display: flex;
  gap: 16px;
  min-height: 200px; 
  max-width: 100%; 
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border: 2px solid rgb(255, 255, 255) ; */
}

.listhead > *:nth-child(1) {
  flex: 6;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listhead > *:nth-child(2) {
  flex: 8;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.listhead > *:nth-child(3) {
  flex: 4;
  display: grid;
}

.oneline {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.detailedit {
  display: flex;
  align-items: end;
  gap: 16px;  
} 
 
 

@media screen and (min-width: 100px) and (max-width: 560px) {
  .listhead{
    display: grid; 
    padding: 20px;
    gap: 10px; 
  }
  
  
  
  .displaygrid {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

@media screen and (min-width: 560px) and (max-width: 980px) {   
  
  .displaygrid {
    grid-template-columns: repeat(1, 1fr);  
  }
}
