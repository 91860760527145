.link_box{
    display: flex; 
    flex-direction: column;  
    text-align: left;  
    /* border: 2px solid black;  */
    position: absolute; 
    top: 0; 
    left: 0;  
    right: 0;  
    gap: 6px; 
}

.headings {
    color: #2e3192;
}

.link_box>*{
    padding: 2px 6px;  
    font-size: large;  
} 

.link_box>*:hover{
    background-color: rgb(219, 238, 255);
}

#table {
    width: fit-content;
}

#table * {
    border: 1px solid black;
    padding: 5px 5px;
}

#table th {
    font-size: medium;
    text-align: center;
}

#table tr {
    font-size: small;
}

li {
    font-size: larger;
}

ul p {
    margin-left: 30px;
}
