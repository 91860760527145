.main-agent {
  display: flex;
  width: 60vw;
  height: auto;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.agent-main-upper {
  display: flex;
  justify-content: space-between;
  width: 90vw;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 40px;
}
.localities {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
}
.localities button{
  background-color: #2E3192;
  color: white;
}
.localities button:hover{
  background-color: #D2AB66;
}
.view-listing{
  background-color: #2E3192;
  color: white;
}
.view-listing{
  background-color: #D2AB66;
}
.agent-searchBar {
  width: 50% !important;
  display: flex;
  justify-content: space-between;
}
.search-box {
  justify-content: center;
  width: 100%;
  margin: 24px 0;
  display: none;
}
.agent-searchBar {
  background-color: aliceblue;
  width: 40vw;
}
.agent-button {
  margin-left: 10px;
  margin-top: 4px;
}
.name-agent {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
}
.main-agent .left-agent img {
  width: 15vw;
  height: 25vh;
  overflow: hidden; /* Ensures the image is cropped if it's too large */
  background-color: white; /* Sets the background color to white */
  display: flex;
  justify-content: center;
  align-items: center;
}
.right {
  width: 100%;
  margin-left: 20px;
}
.para-agent {
  width: -webkit-fill-available;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.about-agent-main {
  padding: 10px 0;
}
.para-agent-company {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}
.para-agent-company span {
  font-weight: 400;
  font-size: 14px;
  color: skyblue;
  margin-left: 10px;
}
.about-agent {
  margin-right: 10px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.para-agent-address {
  padding: 10px 0;
  width: -webkit-fill-available;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.languages {
  padding: 10px 0;
  width: -webkit-fill-available;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.agent-right {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 60vh;
  position: fixed;
  right: 5vw;
  width: 25vw;
  padding: 10px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.agent-searchBar {
  width: 100%;

  padding-right: 30px; /* Add space for the icon */
}

.search-icon {
  position: absolute;
  right: 10px;
  pointer-events: none; /* Prevents the icon from blocking input clicks */
  font-size: 1.9em; /* Adjust the size as needed */
  color: #999; /* Adjust the color as needed */
  top: 20px;
}
.input-container input {
  width: -webkit-fill-available !important;
  margin-top: 20px;
}
.view-listing  {
  background-color: #2E3192 !important;
  color: white !important;
  font-size: 12px !important;
  height: min-content !important;
  padding: 10px 10px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}
.view-listing:hover{
  background-color: #D2AB66 !important;
}


@media only screen and (max-width: 600px) {
  .main-agent {
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: auto;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .main-agent .left-agent img {
    width: 100%;
    height: 20vh;
    overflow: hidden; /* Ensures the image is cropped if it's too large */
    background-color: white; /* Sets the background color to white */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 100%;
    margin-left: 0px;
  }
  .agent-searchBar {
    background-color: aliceblue;
    width: 80vw !important;
  }
  .localities {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 5px 0;
    overflow: hidden;
  }
  .localities button {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .search-box {
    justify-content: center;
    width: 100%;
    margin: 24px 0;
    display: flex;
  }
  .agent-right{
    display: none;
  }
  .agent-main-upper {
    width: auto;
  }
}
