.head_line {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.property_box {
  border-radius: 3px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

/* ============== show Box ================= */
.showbox { 
  background-color: white;  
  -webkit-animation: slide-in-bck-center 2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bck-center 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; 
} 

@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/* =============================== */

.bhkbtn {
  border: 1px solid rgb(235, 235, 235);
  padding: 2px 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
  border-radius: 6px;
  font-size: small;
  align-items: center;
  display: flex;
  background-color: rgb(198, 226, 250);
}

.selectedbtn {
  border: 1px solid rgb(255, 255, 255);
  padding: 2px 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: small;
  border: 1px solid rgb(182, 221, 255);
  /* background-color: rgb(227, 242, 255); */
}

@media all and (min-width: 100px) and (max-width: 560px) {
  .side_box_sort {
    display: none;
  }

  .bhkbtn {
    font-size: x-small;
  }
  .selectedbtn {
    font-size: x-small;
  }
}

@media all and (min-width: 560px) and (max-width: 980px) {
  .bhkbtn {
    font-size: x-small;
  }
  .selectedbtn {
    font-size: x-small;
  }
}
